import {FETCH_CODES} from '../actionTypes';

const codeListReducer = (state = {
    data: [],
    meta: {
        total: 0,
        count: 0,
        per_page: 20,
        current_page: 1,
        total_pages: 1
    }
}, action) => {
    switch (action.type) {
        case FETCH_CODES:
            return action.payload;
        default:
            return state;
    }
};

export default codeListReducer;