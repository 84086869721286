import React, { Component, Fragment } from "react";
import withAuth from "../auth/withAuth";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import {
  fetchPublicProfile,
  updatePublicProfile,
} from "../../redux/actions/publicProfileActions";
import { Field, reduxForm } from "redux-form";
import { fetchCategoryList } from "../../redux/actions/categoryActions";
import UploadModal from "../modals/UploadModal";
import { inputFields } from "../_redux_forms/inputFields";
import {
  required,
  requiredAndEmail,
  validateDescription,
} from "../../utils/validate";
import { textArea } from "../_redux_forms/textArea";
import { multiSelect } from "../_redux_forms/multiSelect";
import ViewProfileModal from "../modals/ViewProfileModal";
import { renderSelect } from "../_redux_forms/radioBtn";
import ServerError from "../common/ServerError";
import FlashMessage from "../common/FlashMessage";
import { showFlashMessage } from "../../redux/actions/flashMessageActions";
import PublishConfirmationModal from "../modals/PublishConfirmationModal";
import { Link } from "react-router-dom";

const yesNoMap = [
  { value: "true", name: "Ja" },
  { value: "false", name: "Nein" },
];

class PublicProfile extends Component {
  state = {
    shop_logo: "",
    shop_logo_link: "",
    shop_logo_error: "",
    shop_banner: "",
    shop_banner_link: "",
    shop_banner_file_name: "",
    shop_banner_error: "",
    shop_image: "",
    shop_image_link: "",
    shop_image_file_name: "",
    shop_image_error: "",
    showModal: false,
    showUploadModal: false,
    showPreviewModal: false,
    showConfirmationModal: false,
    is_loaded: false,
    upload_modal_for: "shop_logo",
    formProps: null,
  };

  async componentDidMount() {
    await this.props.fetchCategoryList();
    await this.props.fetchPublicProfile();
    if (this.props.initialValues.shop_logo_link) {
      this.setState({
        shop_logo_link: this.props.initialValues.shop_logo_link,
      });
    }
    if (this.props.initialValues.shop_banner_link) {
      this.setState({
        shop_banner_link: this.props.initialValues.shop_banner_link,
      });
    }
    if (this.props.initialValues.shop_image_link) {
      this.setState({
        shop_image_link: this.props.initialValues.shop_image_link,
      });
    }
    if (this.props.initialValues.shop_banner_file_name) {
      this.setState({
        shop_banner_file_name: this.props.initialValues.shop_banner_file_name,
      });
    }
    if (this.props.initialValues.shop_image_file_name) {
      this.setState({
        shop_image_file_name: this.props.initialValues.shop_image_file_name,
      });
    }
    this.setState({ is_loaded: true });
  }

  validateImages = () => {
    let valid = true;
    this.setState({
      shop_logo_error: "",
      shop_banner_error: "",
      shop_image_error: "",
    });

    if (this.state.shop_logo_link === "") {
      this.setState({
        shop_logo_error: <Translate content="profile.shop_error" />,
      });
      valid = false;
    }
    if (this.state.shop_banner_file_name === "") {
      this.setState({
        shop_banner_error: <Translate content="profile.shop_error" />,
      });
      valid = false;
    }
    if (this.state.shop_image_file_name === "") {
      this.setState({
        shop_image_error: <Translate content="profile.shop_error" />,
      });
      valid = false;
    }

    return valid;
  };

  onSubmit = (formProps) => {
    if (!this.validateImages) {
      return;
    }
    if (this.state.shop_logo !== "") {
      formProps.shop_logo = this.state.shop_logo;
    }
    if (this.state.shop_banner !== "") {
      formProps.shop_banner = this.state.shop_banner;
    }
    if (this.state.shop_image !== "") {
      formProps.shop_image = this.state.shop_image;
    }
    if (formProps.shipping_service) {
      formProps.provide_shipping_service = formProps.shipping_service.value;
      delete formProps.shipping_service;
    }
    formProps.payment_type = "indirect";

    formProps._method = "PUT";
    formProps.categories.forEach((category, index) => {
      formProps[`categories[${index}]`] = category.id;
    });
    delete formProps.categories;
    delete formProps.city;
    delete formProps.commission;

    if (formProps.status === "published") {
      this.setState({ formProps: formProps });
      this.setShowConfirmationModal(true);
    } else
      this.props.updatePublicProfile(formProps).then((response) => {
        this.props.showFlashMessage(
          <Translate content="profile.save_as_draft" />
        );
        this.props.fetchPublicProfile();
      });
  };

  setShowUploadModal = (showUploadModal) => {
    this.setState({ showUploadModal: showUploadModal });
  };
  setShowPreviewModal = (showPreviewModal) => {
    this.setState({ showPreviewModal: showPreviewModal });
  };
  setShowConfirmationModal = (showConfirmationModal) => {
    this.setState({ showConfirmationModal: showConfirmationModal });
  };

  handleImagePick = (publicProfileImage) => {
    switch (this.state.upload_modal_for) {
      case "shop_logo":
        this.setState({
          shop_logo_link: publicProfileImage.company_logo_url,
          shop_logo: publicProfileImage.company_logo,
        });
        break;
      case "shop_banner":
        this.setState({
          shop_banner_file_name: publicProfileImage.company_logo.name,
          shop_banner_link: publicProfileImage.company_logo_url,
          shop_banner: publicProfileImage.company_logo,
        });
        break;
      case "shop_image":
        this.setState({
          shop_image_file_name: publicProfileImage.company_logo.name,
          shop_image_link: publicProfileImage.company_logo_url,
          shop_image: publicProfileImage.company_logo,
        });
        break;
      default:
    }
    this.validateImages();
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Fragment>
        <section>
          <div className="app-title">
            <h5>
              <Translate content="profile.company_profile" />
            </h5>
          </div>
          <form>
            <div className="app-block partner-page">
              <div className="tile">
                <div className="tile-title-w-btn">
                  <h5 className="title">
                    <Translate content="profile.create_or_change_profile" />
                  </h5>
                  <button
                    className="btn btn-outline-light btn-p-lr btn-preview"
                    onClick={(event) => {
                      event.preventDefault();
                      this.setShowPreviewModal(true);
                    }}
                    disabled={!this.props.publicProfile.published}
                  >
                    <i className="icon-eye"> </i>
                    <Translate content="profile.preview" />
                  </button>
                </div>
                {this.props.initialValues.provide_shipping_service === "" ? (
                  <div className={"alert alert-primary"}>
                    <Translate content={"profile.profile_message"} />
                  </div>
                ) : null}

                <div className="row align-items-start mb-4">
                  <div className="col-md-9">
                    <h5>
                      <Translate content="profile.short_explanatory" />
                    </h5>
                    <p>
                      <Translate content="profile.para" />
                    </p>
                  </div>
                  <div className="col-md-3">
                    <div className="company-logo">
                      <div className="image-holder">
                        <img
                          src={
                            this.state.shop_logo_link !== ""
                              ? this.state.shop_logo_link
                              : "/images/logo_placeholder.jpg"
                          }
                          alt=""
                          height="1795"
                          width="3539"
                        />
                      </div>
                      <Link
                        className="img-changer"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setShowUploadModal(true);
                          this.setState({
                            upload_modal_for: "shop_logo",
                          });
                        }}
                      >
                        <Translate content="profile.upload_logo_or_image" />
                      </Link>
                    </div>
                    {this.state.shop_logo_error !== "" ? (
                      <small className="form-text text-danger">
                        {this.state.shop_logo_error}
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate content="profile.shop_banner" />
                      </label>
                      <div
                        className="custom-file"
                        onClick={() => {
                          this.setShowUploadModal(true);
                          this.setState({
                            upload_modal_for: "shop_banner",
                          });
                        }}
                      >
                        <span className="custom-file-input form-control">
                          &nbsp;
                        </span>
                        <label className="custom-file-label">
                          {this.state.shop_banner_file_name ? (
                            this.state.shop_banner_file_name
                          ) : (
                            <Translate content="profile.choose_shop_banner" />
                          )}
                        </label>
                        {this.state.shop_banner_error !== "" ? (
                          <small className="form-text text-danger">
                            {this.state.shop_banner_error}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        <Translate content="profile.shop_image" />
                      </label>
                      <div
                        className="custom-file"
                        onClick={() => {
                          this.setShowUploadModal(true);
                          this.setState({
                            upload_modal_for: "shop_image",
                          });
                        }}
                      >
                        <span className="custom-file-input form-control">
                          &nbsp;
                        </span>
                        <label className="custom-file-label">
                          {this.state.shop_image_file_name
                            ? this.state.shop_image_file_name
                            : "choose shop image"}
                        </label>
                      </div>
                      {this.state.shop_image_error !== "" ? (
                        <small className="form-text text-danger">
                          {this.state.shop_image_error}
                        </small>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>
                        <Translate content="profile.shop_name" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="shop_name"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.website_url" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="shop_site_url"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.categories" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={multiSelect}
                        data={this.props.categoryList}
                        valueField={"id"}
                        textField={"name"}
                        name="categories"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.contact_name" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="contact_name"
                        validate={required}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.about_the_company" />
                      </label>
                      <Field
                        className="form-control"
                        rows="10"
                        cols="5"
                        type="text"
                        component={textArea}
                        name="description"
                        validate={validateDescription}
                      />
                    </div>
                    <div className="form-group shiping-form">
                      <label>
                        <Translate content="profile.shipping" />
                      </label>
                      <Field
                        component={renderSelect}
                        data={yesNoMap}
                        valueField="value"
                        textField="name"
                        name="shipping_service"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.contact_email" />
                      </label>
                      <Field
                        className="form-control"
                        type="email"
                        component={inputFields}
                        name="contact_email"
                        validate={requiredAndEmail}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        &nbsp;
                        <Translate content="profile.contact_phone_no" />
                      </label>
                      <Field
                        className="form-control"
                        type="number"
                        component={inputFields}
                        name="contact_phone_no"
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tile">
                <div className="tile-title-w-btn contact-title">
                  <h5>
                    <Translate content="profile.payment_section_title" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group shiping-form">
                      <label>
                        <Translate content="profile.payment_type" />
                      </label>
                      <Field
                        component={renderSelect}
                        data={["Paypal"]}
                        defaultValue="Paypal"
                        defaultChecked={true}
                        name="payment_type"
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate content="profile.paypal_id" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="paypal_client_id"
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tile">
                <div className="tile-title-w-btn contact-title">
                  <h5>
                    <Translate content="profile.address_section_title" />
                  </h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate content="profile.street_address" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="street_address"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <Translate content="profile.postal_code" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="postal_code"
                        validate={required}
                      />
                    </div>                    
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate content="profile.house_no" />
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputFields}
                        name="house_no"
                        validate={required}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <Translate content="profile.city" />
                      </label>
                      <span className="form-control disabled">
                        {this.props.authentication.city_name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ServerError />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FlashMessage />
                  </div>
                </div>
              </div>
              <div className="tile-btn">
                <button
                  className="btn btn-secondary btn-p-lr"
                  onClick={handleSubmit((values) => {
                    this.validateImages();
                    this.onSubmit({
                      ...values,
                      status: "draft",
                    });
                  })}
                >
                  <Translate content="profile.save_as_draft" />
                </button>
                <button
                  className="btn btn-primary btn-p-lr"
                  onClick={handleSubmit((values) => {
                    this.validateImages();
                    this.onSubmit({
                      ...values,
                      status: "published",
                    });
                  })}
                >
                  <Translate content="profile.published" />
                </button>
              </div>
            </div>
          </form>
        </section>
        <UploadModal
          showUploadModal={this.state.showUploadModal}
          setShowUploadModal={this.setShowUploadModal}
          onDesignPick={this.handleImagePick}
        />
        {this.state.showPreviewModal && (
          <ViewProfileModal
            showPreviewModal={this.state.showPreviewModal}
            setShowPreviewModal={this.setShowPreviewModal}
            shopLogoLink={
              this.state.shop_logo_link !== ""
                ? this.state.shop_logo_link
                : "/images/logo_placeholder.jpg"
            }
            shopBannerLink={
              this.state.shop_banner_link !== ""
                ? this.state.shop_banner_link
                : "/images/logo_placeholder.jpg"
            }
            shopImageLink={
              this.state.shop_image_link !== ""
                ? this.state.shop_image_link
                : "/images/logo_placeholder.jpg"
            }
          />
        )}
        <PublishConfirmationModal
          show={this.state.showConfirmationModal}
          setShow={this.setShowConfirmationModal}
          onSaveAsDraft={() => {
            const formProps = this.state.formProps;
            this.props
              .updatePublicProfile({
                ...formProps,
                status: "draft",
              })
              .then((response) => {
                this.props.showFlashMessage(
                  "Öffentliches Profil als Entwurf gespeichert."
                );
                this.props.fetchPublicProfile();
              });
            this.setShowConfirmationModal(false);
          }}
          onPublish={() => {
            const formProps = this.state.formProps;
            this.props.updatePublicProfile(formProps).then((response) => {
              this.props.showFlashMessage(
                <Translate content="profile.public_profile_published" />
              );
              this.props.fetchPublicProfile();
            });
            this.setShowConfirmationModal(false);
          }}
        />
      </Fragment>
    );
  }
}

PublicProfile = reduxForm({
  form: "public-profile-form",
  enableReinitialize: true,
})(PublicProfile);

PublicProfile = connect(
  (state) => {
    let initialValues = null;
    if (state.publicProfile.draft) {
      initialValues = state.publicProfile.draft;
    } else if (state.publicProfile.published) {
      initialValues = state.publicProfile.published;
    } else {
      initialValues = {};
    }
    if (initialValues.provide_shipping_service) {
      initialValues.shipping_service = yesNoMap.find(
        (item) => item.value === initialValues.provide_shipping_service
      );
    }
    initialValues.payment_type = "Paypal";

    return {
      authentication: state.authentication,
      categoryList: state.categoryList,
      initialValues: { ...initialValues },
      publicProfile: state.publicProfile,
    };
  },
  {
    fetchCategoryList,
    fetchPublicProfile,
    updatePublicProfile,
    showFlashMessage,
  }
)(PublicProfile);

export default withAuth(PublicProfile);
