import {AUTHENTICATE, DEAUTHENTICATE} from '../actionTypes';

const authReducer = (state = {token: null, city_code: null, city_name: null}, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            return action.payload;
        case DEAUTHENTICATE:
            return {};
        default:
            return state;
    }
};

export default authReducer;