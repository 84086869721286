export default {
  common: {
    contact: "Kontakt",
    registration_any_questions_we_are_happy_to_help_waiter:
      "Anmeldung? Fragen? Wir helfen gerne weiter!",
    login: "Anmelden",
    dashboard: "Übersicht",
    my_profile: "Mein Profil",
    public_profile: "Firmenporträt",
    codes: "Gutscheincodes",
    log_out: "Abmelden",
    upload_codes: "Gutscheincodes hochladen",
    change_password: "Passwort ändern",
    old_password: "Altes Passwort",
    new_password: "Neues Passwort",
    search: "Suchen",
    reset: "Zurücksetzen",
    enter_email: "E-Mail Adresse",
    reset_password: "Passwort zurücksetzen",
    reset_password_link: "Link zum Zurücksetzen des Passworts gesendet. Bitte prüfen Sie Ihre E-Mail Postfach.",
    reset_password_successfully:"Passwort erfolgreich zurückgesetzt. Bitte loggen Sie sich ein.",
    set_password: "Passwort festlegen",
    email_verify_para: "Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse.",
  },
  landing_page: {
    travel_vouchers_from_schenkereisen: "Reisegutscheine von SCHENKEREISEN",
    win_customers_without_effort: "Neue Kunden gewinnen",
    fast_growing_customer_potential: "Schnell wachsendes Kundenpotential",
    no_administrative_burden: "Geringer Verwaltungsaufwand",
    free_advertising_presentation: "Kostenfreie Werbedarstellung",
    information_advice: "INFOS & BERATUNG",
    become_part_of_this_market: "Werden Sie Teil dieses Marktes",
    benefit_from_many_advantages: "PROFITIEREN SIE VON VIELEN VORTEILEN",
    benefit_from_para_1:
      "Reisegutscheine von SCHENKEREISEN sind die ideale Geschenkidee für jeden Anlass. Jederzeit ohne großen Aufwand und in verschiedenen Wertstufen verschenkbar sind sie ein attraktives Verkaufsförderungsmittel in der Reisebranche.\n Setzen auch Sie Ihr Unternehmen kostenfrei in Szene!",
    benefit_from_para_2:
      "Zusätzlich haben auch Arbeitgeber eine wunderbar einfache Möglichkeit, mit den Reisegutscheinen von SCHENKEREISEN ihren Mitarbeitern und Kunden ein Zeichen der Wertschätzung und Motivation zu überreichen. Der Benefit: steuerfreier Sachbezug und für Sie ein schnellwachsender Markt.",
    effectively: "EFFEKTIV",
    effectively_para:
      "Steigern Sie die Attraktivität\n Durch Geschenkkarten für Ihre Zielgruppe",
    benefit: "BENEFIT",
    benefit_para: "Kundenzuwachs durch\n Steuervorteile",
    image: "IMAGE",
    image_para: "Verbesserung des Markenimages\n In Ihrer Zielgruppe",
    presentation: "PRÄSENTATION",
    presentation_para: "Schnelle und einfache\n Umsetzung Ihres Auftrittes",
    chance: "CHANCE",
    chance_para: "Schnell wachsender Markt\n durch Firmenabos",
    added_value: "MEHRWERT",
    added_value_para: "Ohne großen Aufwand\n Kunden gewinnen",
    heres_how_it_works: "So funktioniert’s",
    cooperation_with_gifts: "KOOPERATION MIT SCHENKEREISEN",
    step_1: "Schritt 1",
    step_1_para:
      "Melden Sie Ihr Unternehmen gleich hier auf schenkereisen-b2b.de an.\n Legen Sie Mitarbeiter an oder nutzen Sie unsere komfortablen Upload-Service zur Verwaltung der Gutscheine.",
    step_2: "Schritt 2",
    step_2_para:
      "Bestellen Sie über das Verwaltungsportal jederzeit Ihre gewünschten Gutscheine schnell und einfach online.p Die Übertragung des Gutscheins erfolgt direkt per E-Mail an den Beschenkten.",
    step_3: "Schritt 3",
    step_3_para:
      "Mitarbeiter oder Kunden haben nun die Möglichkeit mehrere Gutscheine zu sammeln oder direkt\n bei www.schenkereisen.de einzulösen.",
    all_information_on_our_b2b_portal: "Alle Infos über unser B2B-Portal",
    here: "hier",
    register_now: "Gleich anmelden!",
    footer_para_1:
      "Für weitere Infos oder ausführliche Beratung\n erreichen Sie uns telefonisch unter",
    footer_para_2: "Oder schreiben Sie uns eine",
    mail: "E-Mail",
    footer_para_3: " und\n wir melden uns gerne bei Ihnen zurück.",
  },
  login: {
    sign_in_to_your_account: "Anmelden",
    enter_email: "Ihre E-Mail-Adresse",
    password: "Passwort",
    forgot_your_password: "Passwort vergessen?",
    reset_now: "Jetzt zurücksetzen",
    dont_have_an_account: "Sie haben noch kein Konto?",
    sign_up_now: "Jetzt registrieren",
    login_para:
      "Indem Sie fortfahren erklären Sie sich mit unseren Nutzungsbedingungen und der Datenschutzerklärung einverstanden.",
    sign_up: "Registrieren",
    enter_username: "Benutzername",
    confirm_password: "Passwort bestätigen",
    already_have_an_account: "Sie haben bereits ein Konto?",
    city: "city",
    registration_successful:
      "erfolgreich registriert.Bitte prüfen sie ihr e-mail postfach.",
  },
  codes: {
    sold: "Verkauft",
    code_list: "Gutscheincodes",
    code: "Code",
    value: "Wert",
    status: "Status",
    created_at: "Erstellt am",
    redeemed: "Eingelöst",
    redeemed_on: "Eingelöst am",
    price: "Preis",
    created_from: "Erstellt von",
    created_to: "Erstellt bis",
    redeemed_to: "Eingelöst von",
    redeemed_from: "Eingelöst bis",
    csv_only: ".csv Only",
    upload: "Upload",
    drop_here_to_upload_or: "Drop here to upload or",
    choose_file: "choose file",
    voucher_code: "Gutscheincode",
    voucher_price: "Gutscheinwert",
    download_sample: "Vorlage herunterladen",
    para: "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.\n Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,\n vulputate\n eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam\n dictum felis eu pede mollis pretium. Integer tincidunt. Beisieldatei ansehen ",
    sample_CSV: "Beispiel-CSV",
    csv_upload: "CSV upload",
    reset: "zurücksetzen",
    coupon_codes: "Couponcodes",
    search_code: "Suche Gutscheincode",
    search_price: "Suche Wert",
    search_status: "Suche Status",
    search: "Suchen",
    action: "Aktion",
    set_as_redeemed: "Einlösen",
    prev: "Vorherige",
    next: "Nächste",
    first: "Erste",
    last: "Letzte",
    modal_yes: "Ja",
    modal_no: "Nein",
    confirm_redeem: "Coupon einlösen",
    are_you_sure_to_do_this: "Sind Sie sicher?",
  },
  dashboard: {
    sold: "Verkauft",
    remain: "Remain",
    dashboard: "Dashboard",
    card_redeem: "Card Redeem",
    added: "added",
    this_week: "This week",
    last_week: "Last week",
    this_month: "This month",
    graph_goes_here: "Graph goes here.",
    coupon_revenue: "Coupon Revenue",
    coupon_distribution: "Coupon Distribution",
    voucher_order: "Gutschein bestellen",
    para: "Sie haben schon Urlaubsfreude verschenkt und möchten gerne weitere\n Gutscheine bestellen?\n haben Sie Fragen, dann kontaktieren Sie uns, wir helfen Ihnen weiter. Gerne nehmen wir\n auch Anmerkungen und Kritik entgegen um unser Portal weiterhin für Sie verbessern zu\n können. Vielen Dank.",
    monthly_turnover: "Umsatz Monat",
    turnover: "Umsatz",
    code_redeemed: "Codes eingelöst",
    code_given: "Codes verschenkt",
    yearly_turnover: "Umsatz Jahr",
    upload_new_code: "Neue Codes uploaden",
    active_code: "Gutscheincodes aktiv",
    coupon_code: "Gutscheincodes Abfragen",
  },
  profile: {
    profile: "Firmenporträt",
    view_public_profile: "View published public profile",
    add_public_profile: "Firmenporträt hinzufügen",
    edit_information: "Firmenporträt bearbeiten",
    add_information: "Create public profile",
    my_profile: "Mein Profil",
    edit_profile: "Profil bearbeiten",
    open_draft: "bearbeiten",
    published: "Publizieren",
    contact_name: "Kontakt Name",
    contact_last_name: "Kontakt Nachname",
    contact_first_name: "Kontakt Vorname",
    email_address: "E-Mail-Adresse",
    phone_number: "Telefon",
    state: "state",
    street: "Straße",
    zip_code: "PLZ",
    country: "Land",
    company_name: "Company name",
    about_the_company: "Über das Unternehmen",
    website_url: "Webseite URL",
    company_logo: "Firmenlogo",
    save_as_draft: "Shopseite als Entwurf speichern",
    categories: "Kategorien",
    no_public_profile_posted_yet: "No public profile posted yet",
    public_profile: "Public profile",
    company_profile: "Shop-Profil",
    upload_logo_or_image: "Logo hochladen",
    short_explanatory: "Kurzer Erklärungstext?",
    preview: "VORSCHAU",
    create_or_change_profile: "PROFIL ANLEGEN / ÄNDERN",
    para: "Hier können Sie festlegen, wie ihr Shop auf dem\n Listinfo-Marketplace präsentiert wird. Laden Sie\n Bilder hoch, und erstellen Sie eine Beschreibung\n über Ihren Shop, um Kunden ihr Angebot bestmöglich zu beschreiben.",
    preview_mode: "Vorschaumodus",
    location: "Location",
    vouchers: "Vouchers",
    shipping: "Versand verfügbar",
    shop_name: "Shop name",
    contact_email: "Kontakt E-Mail Adresse",
    contact_phone_no: "Kontakt Telefonnummer",
    profile_message:
      "Please fill the profile first. Your profile will be verified by admin.",
    payment_type: "Zahlungsart",
    commission: "Kommission",
    paypal_client_id: "Paypal-Kunden-ID",
    payment_section_title: "Wie möchten Sie bezahlt werden?",
    address_section_title: "Wo befindet sich Ihr Shop?",
    shop_banner: "Banner-Bild",
    shop_image: "Shop-Bild",
    browse: "Durchsuchen",
    Vername: "Name",
    shiping: "Versand verfugbar",
    contact_email_address: "Kontakt E-Mail-Adresse",
    contact_phone_number: "Kontakt Telefonnummer",
    direct: "Paypal",
    paypal_id: "Paypal-ID",
    house_no: "Hausnummer",
    street_address: "straße",
    city: "Stadt",
    postal_code: "Postleitzahl",
    upload: "Bild hochladen",
    drag_files_here: "Bilder hierher ziehen",
    or: "Oder",
    choose_shop_banner: "Shop-Banner auswählen",
    yes: "Ja",
    no: "Nein",
    shop_error: "Dieses Feld ist erforderlich.",
    image_errors:
      "*Die maximale Dateigröße: 4 MB. Bitte wählen Sie ein anderes Bild aus",
    publish_confirmation_message:
      "Die Änderungen werden live auf dem Listinfo-Marketplace zu sehen sein!",
    public_profile_published: "Shopseite veröffentlicht.",
    no_data_available_in_table: "Kein Eintrag vorhanden",
  },
  page_not_found: {
    oops: "OOPS! page not found.",
    oops_para: "We cant seem to find the page you're looking for.",
  },
  footer: {
    faqs: "FAQs",
    terms_and_condition: "Nutzungsbedingungen",
    data_protection: "Datenschutzerklärung",
    agbs: "AGBs",
    imprint: "Impressum",
    copyrights: "Urheberrecht",
  },
  tooltip: {
    active: "Aktiv",
    redeemed: "Eingelöst",
    expired: "Abgelaufen",
    inactive: "Inaktiv",
    redeemed_code_status_can_be: "Redeemed code status can be",
    redeem_code_redeemed_date: "Redeem code redeemed date",
    redeem_codes_created_date: "Redeem codes created date",
    price_for_corresponding_code: "Price for corresponding code",
    redeem_codes: "redeem codes",
    codes: "list of redeemed codes",
    sale_increment: "increment in sales",
    sale_decrement: "decrement in sales",
  },
};
