import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/common/Layout";
import Codes from "./components/pages/Codes";
import store from "./redux";
import Login from "./components/pages/Login";
import PageNotFound from "./components/pages/PageNotFound";
import Register from "./components/pages/Register";
import AGBS from "./components/static_pages/AGBS";
import DataProtection from "./components/static_pages/DataProtection";
import FAQ from "./components/static_pages/FAQ";
import Imprint from "./components/static_pages/Imprint";
import TermsAndConditions from "./components/static_pages/TermsAndConditions";
import PublicProfile from "./components/pages/PublicProfile";
import ChangePassword from "./components/pages/ChangePassword";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import SetPassword from "./components/pages/SetPassword";
import EmailVerification from "./components/pages/EmailVerification";
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import "./assets/css/stylesheet.css";

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/change-password" exact component={ChangePassword} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/set-password" component={SetPassword} />

            <Route path="/public-profile" exact component={PublicProfile} />

            <Route path="/codes" exact component={Codes} />

            <Route
              path="/email-verification"
              exact
              component={EmailVerification}
            />

            <Route path="/agbs" exact component={AGBS} />
            <Route path="/data-protection" exact component={DataProtection} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/imprint" exact component={Imprint} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsAndConditions}
            />

            <Route component={PageNotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </AlertProvider>
  </Provider>,
  document.querySelector("#root")
);
