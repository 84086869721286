import React from 'react';
import Translate from 'react-translate-component';

const PageNotFound = () => {
    return (
        <section className="error-page">
            <div className="container-fluid">
                <div className="row align-content-center">
                    <div className="col-md-8 offset-md-2">
                        <div className="tiled">
                            <div className="tile-bg">
                                <h1 className="title">404</h1>
                                <h6 className="subtitle"><Translate content='page_not_found.oops'/></h6>
                                <p><Translate content='page_not_found.oops_para'/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageNotFound;
