import React, { Component } from "react";
import { connect } from "react-redux";
import { deauthenticate } from "../../redux/actions/authActions";
import en from "../../language/en";
import de from "../../language/de";
import Translate, * as counterpart from "react-translate-component";
import { change_language } from "../../redux/actions/languageActions";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("de", de);

counterpart.setLocale(
  "de" || localStorage.getItem("language") || navigator.language.split("-")[0]
);

class Header extends Component {
  onChange = (e) => {
    let language = e.target.value;
    counterpart.setLocale(e.target.value);
    this.props.change_language(language);
  };

  logout = (e) => {
    e.preventDefault();
    this.props.deauthenticate().then(() => {
      window.location.href = "/";
    });
  };

  render() {
    return (
      <header className="app-header">
        {this.props.onAppSidebarClick ? (
          <Link
            className=""
            to="#"
            data-toggle="sidebar"
            aria-label="Hide Sidebar"
            onClick={(event) => {
              event.preventDefault();
              this.props.onAppSidebarClick(!this.props.onAppSidebarClick);
            }}
          >
            {" "}
          </Link>
        ) : null}
        <Link className="app-header__logo logo-img" to="/public-profile">
          <img src={Logo} alt="Logo" />
          <span>Partner</span>
        </Link>
        <ul className="app-nav">
          <li data-tip data-for={"public-profile"}>
            <a
              className={
                ["/public-profile", "/edit-public-profile"].includes(
                  this.props.location.pathname
                )
                  ? "app-menu__item active"
                  : "app-menu__item"
              }
              href={"/public-profile"}
            >
              <svg
                className="bi bi-building"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M15.285.089A.5.5 0 0115.5.5v15a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V14h-1v1.5a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-6a.5.5 0 01.418-.493l5.582-.93V3.5a.5.5 0 01.324-.468l8-3a.5.5 0 01.46.057zM7.5 3.846V8.5a.5.5 0 01-.418.493l-5.582.93V15h8v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V15h2V1.222l-7 2.624z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M6.5 15.5v-7h1v7h-1z"
                  clipRule="evenodd"
                />
                <path d="M2.5 11h1v1h-1v-1zm2 0h1v1h-1v-1zm-2 2h1v1h-1v-1zm2 0h1v1h-1v-1zm6-10h1v1h-1V3zm2 0h1v1h-1V3zm-4 2h1v1h-1V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm-2 2h1v1h-1V7zm2 0h1v1h-1V7zm-4 0h1v1h-1V7zm0 2h1v1h-1V9zm2 0h1v1h-1V9zm2 0h1v1h-1V9zm-4 2h1v1h-1v-1zm2 0h1v1h-1v-1zm2 0h1v1h-1v-1z" />
              </svg>
            </a>
          </li>
          <li data-tip="" data-for={"code-list"}>
            <a
              className={
                this.props.location.pathname === "/codes"
                  ? "app-menu__item active"
                  : "app-menu__item"
              }
              href={"/codes"}
            >
              <svg
                className="bi bi-tag"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M.5 2A1.5 1.5 0 012 .5h4.586a1.5 1.5 0 011.06.44l7 7a1.5 1.5 0 010 2.12l-4.585 4.586a1.5 1.5 0 01-2.122 0l-7-7A1.5 1.5 0 01.5 6.586V2zM2 1.5a.5.5 0 00-.5.5v4.586a.5.5 0 00.146.353l7 7a.5.5 0 00.708 0l4.585-4.585a.5.5 0 000-.708l-7-7a.5.5 0 00-.353-.146H2z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M2.5 4.5a2 2 0 114 0 2 2 0 01-4 0zm2-1a1 1 0 100 2 1 1 0 000-2z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li className="dropdown">
            <Link
              className="app-nav__item dropdown-toggle pr-0"
              to="#"
              data-toggle="dropdown"
              aria-label="Open Profile Menu"
            >
              {this.props.profile.shop_logo_link ? (
                <img
                  src={this.props.profile.shop_logo_link}
                  alt=""
                  className="header-profile"
                />
              ) : (
                <i className="icon-account"> </i>
              )}
              <span className="ml-1">{this.props.profile.shop_name}</span>
            </Link>
            <ul className="dropdown-menu settings-menu dropdown-menu-right">
              <li>
                <a className="dropdown-item" href={"/change-password"}>
                  <i className="icon-password"> </i>
                  <Translate content="common.change_password" />
                </a>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={this.logout}>
                  <i className="icon-exit"> </i>{" "}
                  <Translate content="common.log_out" />
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </header>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.publicProfile.draft
      ? state.publicProfile.draft
      : state.publicProfile.published
      ? state.publicProfile.published
      : {},
  }),
  {
    deauthenticate,
    change_language,
  }
)(Header);
