import {CLEAR_SERVER_ERROR, SET_SERVER_ERROR} from '../actionTypes';

export const setServerError = (errors) => async dispatch => {
    dispatch({
        type: SET_SERVER_ERROR,
        payload: {errors: errors}
    });
};

export const clearServerError = () => async dispatch => {
    dispatch({
        type: CLEAR_SERVER_ERROR,
        payload: null
    });
};

