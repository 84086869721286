export const AUTHENTICATE = "AUTHENTICATE";
export const DEAUTHENTICATE = "DEAUTHENTICATE";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const CLEAR_SERVER_ERROR = "CLEAR_SERVER_ERROR";
export const SHOW_FLASH_MESSAGE = "show_flash_message";
export const HIDE_FLASH_MESSAGE = "hide_flash_message";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PUBLIC_PROFILE = "FETCH_PUBLIC_PROFILE";
export const FETCH_CODES = "FETCH_CODES";
export const CHANGE_LANGUAGE = "change_language";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_CITY_LIST = "FETCH_CITY_LIST";
export const FETCH_CATEGORY_LIST = "FETCH_COUNTRY_LIST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
