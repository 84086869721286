import React, { Fragment } from "react";
import { SelectList } from "react-widgets";

export const renderSelect = ({
  input,
  data,
  textField,
  valueField,
  meta: { error, touched, warning },
}) => {
  return (
    <Fragment>
      <SelectList
        {...input}
        onBlur={() => input.onBlur()}
        data={data}
        textField={textField}
        valueField={valueField}
        onChange={input.onChange}
      />
      {touched &&
        ((error && (
          <span>
            <small className="form-text text-danger">{error}</small>
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </Fragment>
  );
};
