import {HIDE_FLASH_MESSAGE, SHOW_FLASH_MESSAGE} from "../actionTypes";

export const showFlashMessage = (message, color = "primary") => (dispatch) => {
    dispatch({
        type: SHOW_FLASH_MESSAGE,
        payload: {message, color}
    });
};

export const hideFlashMessage = () => (dispatch) => {
    dispatch({
        type: HIDE_FLASH_MESSAGE,
        payload: null
    });
};
