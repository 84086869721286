import React from 'react'

export const textArea = ({
                             input,
                             cols,
                             rows,
                             placeholder,
                             className,
                             meta: {error, touched, warning}
                         }) => {
    return (
        <div>
            <textarea
                {...input}
                cols={cols}
                rows={rows}
                placeholder={placeholder}
                className={className}
            />
            {touched && ((error && (<span><small className="form-text text-danger">{error}</small></span>)) ||
                (warning && <span>{warning}</span>))}
        </div>
    );
};