import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { fetchPublicProfile } from "../../redux/actions/publicProfileActions";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";

class ViewProfileModal extends Component {
  state = {
    loading: false,
  };

  render() {
    const draft = this.props?.form?.["public-profile-form"]?.values;
    const { loading } = this.state;
    let shopLogoLink = this.props.shopLogoLink;
    let shopBannerLink = this.props.shopBannerLink;
    let shopImageLink = this.props.shopImageLink;

    return (
      <Modal
        isOpen={this.props.showPreviewModal}
        className="partner-preview-modal"
      >
        <ModalHeader
          toggle={() => {
            this.props.setShowPreviewModal(!this.props.showPreviewModal);
          }}
        >
          <Translate content="profile.preview_mode" />
        </ModalHeader>
        {loading ? (
          "Loading"
        ) : draft ? (
          <ModalBody>
            <div
              className="banner"
              style={{ backgroundImage: `url(${shopBannerLink})` }}
            ></div>
            <div
              className="modal-logo"
              style={{ backgroundImage: `url(${shopLogoLink})` }}
            ></div>

            <h2 className="preview-shop-name">{draft.shop_name}</h2>
            <div className="shop-preview-content">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Beschreibung</h3>
                  <p>{draft.description}</p>
                  <ul className="business-tag">
                    {draft.categories.map((category) => (
                      <li className="badge badge-dark" key={category.id}>
                        {category.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-6 preview-right-image">
                  <img src={shopImageLink} alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h3>Coupon</h3>
                <p>
                  Etiam convallis elementum sapien, a aliquam turpis aliquam
                  vitae. Praesent sollicitudin felis vel mi facilisis posuere.
                  Nulla ultrices facilisis justo, non varius nisl semper vel.
                  Interdum et malesuada fames ac ante ipsum primis in
                  faucibus.&nbsp;
                </p>
                <button className="btn btn-primary">Order Coupon</button>
                <div className="preview-shipping">
                  <svg
                    className="bi bi-check checkmark"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Versand möglich?
                  <strong>
                    {draft
                      ? draft.provide_shipping_service === "true"
                        ? " Ja "
                        : " Nein "
                      : ""}
                  </strong>
                </div>
              </div>
              <div className="col-sm-6">
                <h3>Wie sie uns finden</h3>
                <ul className="preview-contact-info">
                  <li>
                    <i className="icon-user-o"> </i>
                    <span>{draft.contact_name}</span>
                  </li>
                  <li>
                    <i className="icon-pin"> </i>
                    <span>
                      {draft.street_address} {draft.house_no}, {draft.postal_code},&nbsp;
                      {draft.city.name}
                    </span>
                  </li>
                  <li>
                    <i className="icon-phone"></i>
                    <span>{draft ? draft.contact_phone_no : ""}</span>
                  </li>
                  <li>
                    <i className="icon-mail"></i>
                    <span>
                      <Link to="#">{draft.contact_email}</Link>
                    </span>
                  </li>
                  <li>
                    <i className="icon-earth"></i>
                    <span>
                      <a
                        href={draft.shop_site_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {draft.shop_site_url}
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row other-shops">
              <div className="col-sm-12">
                <div className="inner">
                  <div className="preview-center-content">
                    <h3>Eine anderen Partner wählen</h3>
                    <select className="form-control">
                      <option>Partner auswählen</option>
                      <option>Shop#1</option>
                      <option>Shop#2</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        ) : (
          "No profile is published yet."
        )}
      </Modal>
    );
  }
}

ViewProfileModal = connect(
  (state) => ({
    publicProfile: state.publicProfile,
    form: state.form,
  }),
  {
    fetchPublicProfile,
  }
)(ViewProfileModal);

export default ViewProfileModal;
