import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";

const NoTableData = ({ list, colSpan }) => {
  return list.length === 0 ? (
    <tr className="odd text-center">
      <td valign="top" colSpan={colSpan} className="dataTables_empty">
        <Translate content="profile.no_data_available_in_table" />
      </td>
    </tr>
  ) : null;
};

NoTableData.propTypes = {
  list: PropTypes.array.isRequired,
  colSpan: PropTypes.number.isRequired,
};

export default NoTableData;
