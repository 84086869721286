import {FETCH_PUBLIC_PROFILE} from "../actionTypes";

const publicProfileReducer = (state = {published: null, draft: null}, action) => {
    switch (action.type) {
        case FETCH_PUBLIC_PROFILE:
            return action.payload;
        default:
            return {...state};
    }
};

export default publicProfileReducer;