import React, { Component } from "react";
import { connect } from "react-redux";
import { register } from "../../redux/actions/authActions";
import { inputFields } from "../_redux_forms/inputFields";
import {
  required,
  requiredAndEmail,
  requiredAndPassword,
} from "../../utils/validate";
import { Field, reduxForm, SubmissionError } from "redux-form";
import ServerError from "../common/ServerError";
import withNoAuth from "../auth/withNoAuth";
import Translate from "react-translate-component";
import { fetchCityList } from "../../redux/actions/cityActions";
import { withAlert } from "react-alert";
import bgImage from "../../assets/images/market_partner.png";
import { Link } from "react-router-dom";

class Register extends Component {
  onSubmit = async (formProps) => {
    formProps.role = "partner";
    try {
      await this.props.register(formProps);
      this.props.alert.show(
        <Translate content="login.registration_successful" />,
        { type: "success" }
      );
      this.props.history.push("/");
    } catch (e) {
      if (e?.response?.data?.errors) {
        throw new SubmissionError(e.response.data.errors);
      }
      if (e?.response?.data?.message) {
        this.props.alert.show(e?.response?.data?.message, {
          type: "error",
        });
        return;
      }

      this.props.alert.show(e.message, { type: "error" });
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <section className="login-page">
        <div className="container ">
          <div className="auth-block">
            <h5 className="auth-title">
              <Translate content="login.sign_up" />
            </h5>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-group">
                <label>
                  <Translate content="login.enter_username" />
                </label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputFields}
                  name="name"
                  validate={required}
                />
              </div>

              <div className="form-group">
                <label>
                  <Translate content="login.enter_email" />
                </label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputFields}
                  name="email"
                  validate={requiredAndEmail}
                />
              </div>
              <div className="form-group">
                <label>
                  <Translate content="login.password" />
                </label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputFields}
                  name="password"
                  validate={requiredAndPassword}
                />
              </div>
              <div className="form-group">
                <label>
                  <Translate content="login.confirm_password" />
                </label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputFields}
                  name="password_confirmation"
                  validate={required}
                />
              </div>
              <div className="form-group">
                <ServerError />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  <Translate content="login.sign_up" />
                </button>
              </div>
              <div className="form-group text-center">
                <Translate content="login.already_have_an_account" />
                <Link to="/">
                  &nbsp;
                  <Translate content="common.login" />
                </Link>
              </div>
              <p className="small text-center">
                <Translate content="login.login_para" />
              </p>
            </form>
          </div>
          <div className="login-image">
            <img src={bgImage} alt="background-img" />
          </div>
        </div>
      </section>
    );
  }
}

Register = reduxForm({
  form: "register",
})(Register);

export default connect((state) => ({ cityList: state.cityList }), {
  fetchCityList,
  register,
})(withNoAuth(withAlert()(Register)));
