import { AUTHENTICATE, DEAUTHENTICATE, SET_SERVER_ERROR } from "../actionTypes";
import httpClient, { serverErrorToErrorList } from "../../utils/httpClient";
import jwt from "jsonwebtoken";

export const authenticate = (user) => async (dispatch) => {
  try {
    let response = await httpClient.post(`/login`, user);
    const { token, city_code, city_name } = response.data.data;
    const { sub } = jwt.decode(token);
    dispatch({
      type: AUTHENTICATE,
      payload: { token, city_code, city_name, user_id: sub },
    });
    localStorage.setItem("token", token);

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const deauthenticate = () => async (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: DEAUTHENTICATE });
};

export const register = (user) => async (dispatch) => {
  let response = await httpClient.post(`/register`, user, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};
export const verifyEmail = (formdata) => async (dispatch) => {
  let response = await httpClient.put(`/email-verification`, formdata, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};
