import React, {Component} from 'react'
import withAuth from "../auth/withAuth";
import {Field, reduxForm} from "redux-form";
import {inputFields} from "../_redux_forms/inputFields";
import {connect} from "react-redux";
import {changePassword} from "../../redux/actions/passwordActions";
import {showFlashMessage} from "../../redux/actions/flashMessageActions";
import {required, requiredAndPassword, same} from "../../utils/validate";
import ServerError from "../common/ServerError";
import {deauthenticate} from "../../redux/actions/authActions";
import Translate from 'react-translate-component';

class ChangePassword extends Component {

    handleSubmit = formProps => {
        this.props.changePassword(formProps).then(response => {
            this.props.deauthenticate().then(() => {
                this.props.history.push("/")
                this.props.showFlashMessage(response.message);
            });
        })
    };

    render() {
        const {handleSubmit} = this.props;
        return (
            <section className="login-page">
                <div className="container">
                    <div className="auth-block">
                        <h5 className="auth-title"><Translate content='common.change_password'/></h5>
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className="form-group">
                                <label><Translate content='common.old_password' /></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="password"
                                       name="old_password"
                                       validate={required}
                                />
                            </div>
                            <div className="form-group">
                                <label><Translate content='common.new_password' /></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="password"
                                       name="new_password"
                                       validate={requiredAndPassword}
                                />
                            </div>
                            <div className="form-group">
                                <label><Translate content='login.confirm_password'/></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="password"
                                       name="new_password_confirmation"
                                       validate={same}
                                />
                            </div>
                            <div className="form-group">
                                <ServerError/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit"><Translate content='common.change_password' /> </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

ChangePassword = reduxForm({
    form: "change-password"
})(ChangePassword);

ChangePassword = connect(null,
    {
        changePassword,
        showFlashMessage,
        deauthenticate
    }
)(ChangePassword);
export default withAuth(ChangePassword);