import React, {Component} from 'react'
import withNoAuth from "../auth/withNoAuth";
import {Field, reduxForm} from "redux-form";
import {inputFields} from "../_redux_forms/inputFields";
import {requiredAndPassword} from "../../utils/validate";
import {connect} from "react-redux";
import {resetPassword} from "../../redux/actions/passwordActions";
import qs from 'qs';
import ServerError from "../common/ServerError";
import {showFlashMessage} from "../../redux/actions/flashMessageActions";
import Translate from "react-translate-component";

class ResetPassword extends Component {

    handleSubmit = formProps => {
        formProps.token = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).token;
        this.props.resetPassword(formProps).then(response => {
            this.props.history.push('/');
            this.props.showFlashMessage(<Translate content='common.reset_password_successfully'/>);
        })
    };

    render() {
        const {handleSubmit} = this.props;

        return (
            <section className="login-page">
                <div className="container">
                    <div className="auth-block">
                        <h5 className="auth-title"><Translate content='common.set_password'/></h5>
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className="form-group">
                                <label><Translate content='common.new_password'/></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="password"
                                       name='password'
                                       validate={requiredAndPassword}
                                />
                            </div>
                            <div className="form-group">
                                <label><Translate content='login.confirm_password'/></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="password"
                                       name="password_confirmation"
                                       validate={requiredAndPassword}
                                />
                            </div>
                            <div className="form-group">
                                <ServerError/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit"><Translate content='common.set_password'/></button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

ResetPassword = reduxForm({
    form: 'reset-password'
})(ResetPassword);
ResetPassword = connect(null, {resetPassword, showFlashMessage})(ResetPassword)
export default withNoAuth(ResetPassword);