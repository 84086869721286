import {SET_SERVER_ERROR} from "../actionTypes";
import {authHttpClient, serverErrorToErrorList} from "../../utils/httpClient";

export const changePassword = (data) => async dispatch => {
    try {
        let response = await authHttpClient.post(`/change-password`, data);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
            throw e;

    }
}

export const forgotPassword = email => async dispatch => {
    try {
        let response = await authHttpClient.post(`/forgot-password`, email);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
            throw e;

    }
}

export const resetPassword = data => async dispatch => {
    try {
        let response = await authHttpClient.post(`/reset-password`, data);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
            throw e;

    }
}

export const setPassword = data => async dispatch => {
    try {
        let response = await authHttpClient.post(`/set-password`, data);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
            throw e;
    }
}