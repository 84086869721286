import {FETCH_CITY_LIST} from '../actionTypes';

const cityReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_CITY_LIST:
            return action.payload;
        default:
            return state;
    }
};

export default cityReducer;