import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import {
  fetchPublicProfile,
  updatePublicProfile,
} from "../../redux/actions/publicProfileActions";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";

const maxSize = 4194304;

class UploadModal extends Component {
  state = {
    company_logo_file_name: null,
    image_errors: "",
  };

  onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].size > maxSize) {
      this.setState({
        company_logo_file_name: acceptedFiles[0].name,
        image_errors: <Translate content="profile.image_errors" />,
      });

      return;
    }
    const formProps = {
      company_logo_url: URL.createObjectURL(acceptedFiles[0]),
      company_logo: acceptedFiles[0],
    };

    this.props.onDesignPick(formProps);
    this.props.setShowUploadModal(false);
  };

  render() {
    return (
      <Modal className="logo_upload" isOpen={this.props.showUploadModal}>
        <ModalHeader
          toggle={() => {
            this.props.setShowUploadModal(!this.props.showUploadModal);
          }}
        >
          <Translate content="profile.upload" />
        </ModalHeader>
        <ModalBody className="img-changer upload_image">
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="text-center">
                    <svg
                      className="bi bi-upload text-primary"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8zM5 4.854a.5.5 0 00.707 0L8 2.56l2.293 2.293A.5.5 0 1011 4.146L8.354 1.5a.5.5 0 00-.708 0L5 4.146a.5.5 0 000 .708z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <h4 className="mt-3">
                      <Translate content="profile.drag_files_here" />
                    </h4>
                    <span>
                      <Translate content="profile.or" />
                    </span>
                    <h3 className="mt-2">
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={(event) => event.preventDefault()}
                      >
                        <Translate content="profile.browse" />
                      </Link>
                    </h3>
                    {this.state.company_logo_file_name ? (
                      <h5>{this.state.company_logo_file_name}</h5>
                    ) : null}
                    {this.state.image_errors !== "" ? (
                      <small className="form-text text-danger">
                        {this.state.image_errors}
                      </small>
                    ) : null}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <small className="form-text text-danger">
            <small className="form-text text-danger">
              {this.state.image_error}
            </small>
          </small>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(null, {
  fetchPublicProfile,
  updatePublicProfile,
})(UploadModal);
