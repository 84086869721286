import React, {Component, Fragment} from "react";
import Header from "../common/Header";
import NoAuthHeader from "../common/NoAuthHeader";
import WithAuthFooter from "../common/WithAuthFooter";

class AGBS extends Component {
    render() {
        const token = localStorage.getItem('token')
        return (
            <Fragment>
                {token !== null ? <Header/> : <NoAuthHeader/>}
                <section className="page-padding home-content">
                    <div className="container mt-5">
                        <div className="page-heading">
                            <div className="app-title">
                                <h5>AGBS</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <WithAuthFooter/>
            </Fragment>
        );
    }
}

export default (AGBS);