import {authHttpClient, serverErrorToErrorList} from "../../utils/httpClient";
import {FETCH_CATEGORY_LIST, SET_SERVER_ERROR} from "../actionTypes";

export const fetchCategoryList = (query = {}) => async (dispatch) => {
    try {
        let response = await authHttpClient.get(`/categories`);

        dispatch({
            type: FETCH_CATEGORY_LIST,
            payload: response.data.data
        });

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
        throw e
    }
};