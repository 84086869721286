import React, { Component, Fragment } from "react";
import Translate from "react-translate-component";
import footerIcon from "../../assets/icons/footerIcon-1.svg";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div className="process-footer">
          <div className="process-footer-row">
            <div className="process-footer-col">
              <ul className="nav footer-links">
                <li className="copy">
                  <img className="footerIcons" src={footerIcon} alt="" />
                  <Translate content="footer.copyrights" /> © 2022{" "}
                  <a href="https://stadt-retten.diagonal.solutions/">
                    list-marketplace.de
                  </a>
                </li>
              </ul>
            </div>
            <div className="align-items-end">
              <ul className="nav footer-links">
                <li>
                  <a href="/faq" target="_blank">
                    <Translate content="footer.faqs" />
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions" target="_blank">
                    <Translate
                      content="footer.terms_and_condition"
                      target="_blank"
                    />
                  </a>
                </li>
                <li>
                  <a href="/data-protection" target="_blank">
                    <Translate content="footer.data_protection" />
                  </a>
                </li>
                <li>
                  <a href="/imprint" target="_blank">
                    <Translate content="footer.imprint" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
