import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import jwt from "jsonwebtoken";
import Header from "../common/Header";
import WithAuthFooter from "../common/WithAuthFooter";
import {fetchPublicProfile} from "../../redux/actions/publicProfileActions";

export default ChildComponent => {

    class ComposedComponent extends Component {
        state = {
            setMinimized: false
        }

        componentDidMount() {
            this.shouldNavigateAway();
        }

        componentDidUpdate() {
            this.shouldNavigateAway();
        }

        shouldNavigateAway() {
            // check if the token exists, if not redirect to landing page
            if (!this.props.authentication.token) {
                this.props.history.push("/");
            } else {
                const {exp} = jwt.decode(this.props.authentication.token);
                // check if the token is valid, if not remove token from localstorage and redirect to landing page
                if ((exp * 1000) < (new Date()).getTime()) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('city_code');
                    localStorage.removeItem('city_name');
                    this.props.history.push('/');
                } else {
                    this.props.fetchPublicProfile();
                }
            }
        }

        onAppSidebarClick = (event) => {
            this.setState({
                setMinimized: (!this.state.setMinimized)
            });
        };

        render() {
            return (
                <Fragment>
                    <div
                        className={this.state.setMinimized ? "app sidebar-mini rtl sidenav-toggled" : "app sidebar-mini rtl"}>
                        <Header location={this.props.location}
                                history={this.props.history}
                                onAppSidebarClick={this.onAppSidebarClick}
                        />
                        {/* <Sidebar location={this.props.location}
                                 history={this.props.history}/> */}
                        <div className="app-content">
                            <ChildComponent {...this.props} />

                        </div>
                        <WithAuthFooter/>

                    </div>
                </Fragment>);
        }
    }

    function mapStateToProps(state) {
        return {
            authentication: state.authentication
        };
    }

    return connect(mapStateToProps, {
        fetchPublicProfile
    })(ComposedComponent);
};
