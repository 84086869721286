export default {
  common: {
    contact: "Contact",
    registration_any_questions_we_are_happy_to_help_waiter:
      "Registration? Any question? We are happy to help waiter!",
    login: "Login",
    dashboard: "Dashboard",
    my_profile: "My profile",
    public_profile: "Public profile",
    codes: "Codes",
    log_out: "Log out",
    upload_codes: "Upload Codes",
    change_password: "Change Password",
    old_password: "Old Password",
    new_password: "New Password",
    search: "Search",
    reset: "Reset",
    enter_email: "Enter email",
    reset_password: "Reset Password",
    set_password: "Set Password",
    email_verify_para:
      "Thank your for email verification. Welcome to Save your city.",
  },
  landing_page: {
    travel_vouchers_from_schenkereisen: "Travel vouchers from SCHENKEREISEN",
    win_customers_without_effort: "win customers without effort",
    fast_growing_customer_potential: "fast growing customer potential",
    no_administrative_burden: "no administrative effort",
    free_advertising_presentation: "Free advertising",
    information_advice: "INFORMATION & ADVICE",
    become_part_of_this_market: "Become part of this market",
    benefit_from_many_advantages: "BENEFIT FROM MANY ADVANTAGES",
    benefit_from_para_1:
      "Travel vouchers from SCHENKEREISEN are the ideal gift idea for every occasion. They can be given away at any time without great effort and in different value levels and are an attractive sales promotion tool in the travel industry.\n Put your company in the limelight free of charge!",
    benefit_from_para_2:
      "In addition, employers also have a wonderfully simple way of presenting their employees and customers with a token of appreciation and motivation using SCHENKEREISEN travel vouchers. The benefit: tax-free benefits in kind and for you a fast growing market.",
    effectively: "EFFECTIVELY",
    effectively_para:
      "Increase the attractiveness\n" +
      "Through gift cards for your target group",
    benefit: "BENFIT",
    benefit_para: "High customer growth due to a\n Tax advantages",
    image: "IMAGE",
    image_para: "Improvement of the brand image\n In your target group",
    presentation: "PRESENTATION",
    presentation_para: "Quick and easy\n Realization of your appearance",
    chance: "CHANCE",
    chance_para: "Fast growing market\n through company subscriptions",
    added_value: "ADDED VALUE",
    added_value_para: "Without much effort\n acquire customers",
    heres_how_it_works: "Here’s how it works",
    cooperation_with_gifts: "COOPERATION WITH GIFTS",
    step_1: "Step 1",
    step_1_para:
      "Register your company right here on schenkereisen-b2b.de.\n Create employees or use our comfortable upload service to manage the vouchers.",
    step_2: "Step 2",
    step_2_para:
      "Order your desired vouchers quickly and easily online at any time via the administration portal.p The voucher is transferred directly to the presentee by e-mail.",
    step_3: "Step 3",
    step_3_para:
      "Employees or customers now have the possibility to collect several vouchers or \n to be redeemed at www.schenkereisen.de.",
    all_information_on_our_b2b_portal: "All information on our B2B portal",
    here: "here",
    register_now: "Register now!",
    footer_para_1:
      "For further information or detailed advice you can reach us by phone at",
    footer_para_2: "Or write us one",
    mail: "Mail",
    footer_para_3: " and we will be happy to get back to you",
  },
  login: {
    sign_in_to_your_account: "SIGN IN TO YOUR ACCOUNT",
    enter_email: "Enter email",
    password: "Password",
    forgot_your_password: "Forgot your password?",
    reset_now: "Reset Now",
    dont_have_an_account: "Don't have an Account?",
    sign_up_now: "Sign Up Now",
    login_para:
      "By continuing you agree to our Terms and Conditions, our Privacy Policy.",
    sign_up: "SIGN UP",
    enter_username: "Enter Username",
    confirm_password: "Confirm Password",
    already_have_an_account: "Already have an account",
    city: "city",
    registration_successful:
      "successfully registered. Please check your email inbox.",
  },
  codes: {
    sold: "Sold",
    code_list: "Code List",
    code: "Code",
    value: "Value",
    status: "Status",
    created_at: "Created At",
    redeemed: "Redeemed",
    redeemed_on: "Redeemed on",
    price: "price",
    created_from: "Created From",
    created_to: "Created To",
    redeemed_to: "Redeemed To",
    redeemed_from: "Redeemed From",
    csv_only: ".csv Only",
    upload: "Upload",
    drop_here_to_upload_or: "Drop here to upload or",
    choose_file: "choose file",
    voucher_code: "Voucher code",
    voucher_price: "Voucher price",
    download_sample: "Download Sample",
    para: "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.\n Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,\n vulputate\n eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam\n dictum felis eu pede mollis pretium. Integer tincidunt. Beisieldatei ansehen ",
    sample_CSV: "Sample-CSV",
    csv_upload: "CSV upload",
    action: "Action",
    search: "Search",
    search_code: "Search Code",
    search_price: "Search Price",
    reset: "Reset",
  },
  dashboard: {
    sold: "Sold",
    remain: "Remain",
    dashboard: "Dashboard",
    card_redeem: "Card Redeem",
    added: "added",
    this_week: "This week",
    last_week: "Last week",
    this_month: "This month",
    graph_goes_here: "Graph goes here.",
    coupon_revenue: "Coupon Revenue",
    coupon_distribution: "Coupon Distribution",
    voucher_order: "Voucher order",
    para: "Sie haben schon Urlaubsfreude verschenkt und möchten gerne weitere\n Gutscheine bestellen?<br/>\n haben Sie Fragen, dann kontaktieren Sie uns, wir helfen Ihnen weiter. Gerne nehmen wir\n auch Anmerkungen und Kritik entgegen um unser Portal weiterhin für Sie verbessern zu\n können. Vielen Dank.",
    monthly_turnover: "Monthly turnover",
    turnover: "turnover",
    code_redeemed: "Codes redeemed",
    code_given: "Codes given",
    yearly_turnover: "Yearly turnover",
    upload_new_code: "Upload new code",
    active_code: "Active code",
    coupon_code: "Coupon code",
  },
  profile: {
    profile: "Company profile",
    view_public_profile: "View published public profile",
    add_public_profile: "Add public profile",
    edit_information: "Edit company profile",
    add_information: "Create public profile",
    my_profile: "My Profile",
    edit_profile: "Edit Profile",
    open_draft: "Open draft",
    published: "Published",
    contact_name: "Contact name",
    contact_last_name: "Contact last name",
    contact_first_name: "Contact first name",
    email_address: "Email address",
    phone_number: "Phone Number",
    state: "State",
    street: "street",
    zip_code: "zip code",
    country: "country",
    company_name: "Company name",
    about_the_company: "About the company",
    website_url: "Website URL",
    company_logo: "Company logo",
    save_as_draft: "Save as draft",
    categories: "Categories",
    no_public_profile_posted_yet: "No public profile posted yet",
    public_profile: "Public profile",
    company_profile: "Company profile",
    upload_logo_or_image: "Upload Logo",
    short_explanatory: "Short explanatory?",
    preview: "Preview",
    create_or_change_profile: "Create/change profile",
    para: "Here YOU can specify how your shop WILL be presented\n on the Listinfo marketplace. Upload images and \n create a description about your store to best describe\n what you offer to customers.",
    preview_mode: "Prevoew mode",
    location: "Location",
    vouchers: "Vouchers",
    shipping: "Shipping",
    shop_name: "Shop name",
    contact_email: "Contact email address",
    contact_phone_no: "Contact phone number",
    profile_message:
      "Please fill the profile first. Your profile will be verified by admin.",
    payment_type: "Payment type",
    commission: "Commission",
    paypal_client_id: "Paypal client id",
    payment_section_title: "How do you want to be paid?",
    address_section_title: "How to find us?",
    shop_banner: "Shop Banner",
    shop_image: "Shop Image",
    browse: "Browse",
    Vername: "Name",
    shiping: "Shiping",
    contact_email_address: "Contact Email Address",
    contact_phone_number: "Contact Phone Number",
    direct: "Paypal",
    paypal_id: "Paypal-ID",
    house_no: "House No",
    street_address: "Street Address",
    city: "City",
    postal_code: "Postal Code",
    upload: "Upload",
    drag_files_here: "Drag files here",
    or: "or",
    coupon_codes: "Couponcodes",
    search_code: "Search Code",
    search_price: "Search Price",
    search_status: "Search Status",
    search: "Search",
    action: "Action",
    set_as_redeemed: "Set As Redeemed",
    prev: "prev",
    next: "next",
    first: "first",
    last: "last",
    modal_yes: "Yes",
    modal_no: "No",
    confirm_redeem: "Confirm redeem",
    are_your_sure_to_do_this: "Are you sure to do this?",
    choose_shop_banner: "Choose Shop Banner",
    yes: "Yes",
    no: "No",
    shop_error: "This field is required.",
    image_errors: "*Maximum size is 4 MB. Please select another image",
    publish_confirmation_message:
      "The changes will be live on the Listinfo marketplace!",
    public_profile_published: "Public profile published.",
    no_data_available_in_table: "No data available in the table",
  },
  page_not_found: {
    oops: "OOPS! page not found.",
    oops_para: "We cant seem to find the page you're looking for.",
  },
  footer: {
    faqs: "FAQs",
    terms_and_condition: "Terms and condition",
    data_protection: "Data protection",
    agbs: "AGBs",
    imprint: "Imprint",
    copyrights: "copyrights",
  },
  tooltip: {
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    inactive: "Inactive",
    redeemed_code_status_can_be: "Redeemed code status can be",
    redeem_code_redeemed_date: "Redeem code redeemed date",
    redeem_codes_created_date: "Redeem codes created date",
    price_for_corresponding_code: "Price for corresponding code",
    redeem_codes: "redeem codes",
    codes: "list of redeemed codes",
  },
};
