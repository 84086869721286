import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../assets/images/Logo.svg";

class NoAuthHeader extends Component {
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-md">
          <div className="container">
            <div className="top-header-menu">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <NavLink className="navbar-brand" to="/">
                  <img src={Logo} alt="Logo" />
                </NavLink>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <ul className="nav main-menu justify-content-end">
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link"
                      to="/"
                    >
                      {/* <i className="icon-group-o"></i> */}
                      Partner
                      <img
                        className="navIcons ml-2"
                        src="images/Partner.svg"
                        alt="Partner"
                        width="18px"
                        height="18px"
                      />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"> </span>
            </button>
            <div className="navheader">
              <div className="top-header">
                <ul className="nav justify-content-md-end">
                  <li className="kontakt-block" />
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default connect()(NoAuthHeader);
