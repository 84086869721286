import React, {Fragment} from 'react'

export const inputFields = ({
                                input,
                                placeholder,
                                type,
                                className,
                                meta: {touched, error, warning},
                                min,
                                max
                            }) => {
    return (
        <Fragment>
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className={className}
                min={min}
                max={max}
            />
            {touched && (
                (error && (<span><small className="form-text text-danger">{error}</small></span>))
                || (warning && <span>{warning}</span>)
            )}
        </Fragment>
    )
};