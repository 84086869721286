import {FETCH_CATEGORY_LIST} from '../actionTypes';

const categoryReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_CATEGORY_LIST:
            return action.payload;
        default:
            return state;
    }
};

export default categoryReducer;