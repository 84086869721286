import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { Component } from "react";
import Translate from "react-translate-component";

class RedeemConfirmationModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.show}>
        <ModalHeader
          toggle={() => {
            this.props.setShow(!this.props.show);
          }}
        >
          <Translate content="codes.confirm_redeem" />
        </ModalHeader>
        <ModalBody>
          <Translate content="codes.are_you_sure_to_do_this" />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={this.props.onYes}>
            <Translate content="codes.modal_yes" />
          </button>
          <button className="btn btn-primary" onClick={this.props.onNo}>
            <Translate content="codes.modal_no" />
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RedeemConfirmationModal;
