import React, {Component} from 'react'
import withNoAuth from "../auth/withNoAuth";
import {Field, reduxForm} from "redux-form";
import {inputFields} from "../_redux_forms/inputFields";
import {requiredAndEmail} from "../../utils/validate";
import {connect} from "react-redux";
import {forgotPassword} from "../../redux/actions/passwordActions";
import ServerError from "../common/ServerError";
import {showFlashMessage} from "../../redux/actions/flashMessageActions";
import FlashMessage from "../common/FlashMessage";
import Translate from "react-translate-component";

class ForgotPassword extends Component {
    state = {
        submit: false,
        email: null,
    };

    handleSubmit = formProps => {
        this.props.forgotPassword(formProps).then(response => {
            this.setState({
                submit: true,
                email: ''
            });
            this.props.showFlashMessage(<Translate content='common.reset_password_link'/>);
        })
    };

    render() {
        const {handleSubmit} = this.props;
        return (
            <section className="login-page">
                <div className="container">
                    <FlashMessage/>
                    <div className="auth-block">
                            <h5 className="auth-title"><Translate content='common.reset_password'/></h5>
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className="form-group">
                                    <label><Translate content='common.enter_email'/></label>
                                <Field component={inputFields}
                                       className="form-control"
                                       type="email"
                                       name="email"
                                       validate={requiredAndEmail}
                                />
                            </div>
                            <div className="form-group">
                                <ServerError/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit"><Translate content='common.reset'/></button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

ForgotPassword = reduxForm({
    form: 'forgot-password'
})(ForgotPassword);

ForgotPassword = connect(null, {
    forgotPassword,
    showFlashMessage
})(ForgotPassword);

export default withNoAuth(ForgotPassword)