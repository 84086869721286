import {authHttpClient, filterQueryParams, serverErrorToErrorList} from "../../utils/httpClient";
import {FETCH_CODES, SET_SERVER_ERROR} from "../actionTypes";


export const fetchCodeList = (params = {}) => async (dispatch) => {
    try {
        let response = await authHttpClient.get(`/coupons`, {params: filterQueryParams(params)});

        dispatch({
            type: FETCH_CODES,
            payload: response.data
        });

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
        throw e;

    }
};

export const updateCode = (data) => async (dispatch) => {
    try {
        let response = await authHttpClient.put(`/coupon-status`, data);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
        throw e;
    }
};

