import {CLEAR_SERVER_ERROR, FETCH_PUBLIC_PROFILE, SET_SERVER_ERROR} from "../actionTypes";
import {authHttpClient, authMultipartFormDataHttpClient, serverErrorToErrorList} from "../../utils/httpClient";

export const fetchPublicProfile = () => async dispatch => {
    let publishedPublicProfile = null;
    let draftPublicProfile = null;
    try {
        const response = await authHttpClient.get(`/partner-public-profile`, {
            params: {status: 'published'}
        });
        publishedPublicProfile = response.data.data;
    } catch (e) {
    }

    try {
        const response = await authHttpClient.get(`/partner-public-profile`, {
            params: {status: 'draft'}
        });
        draftPublicProfile = response.data.data;
    } catch (e) {
    }

    dispatch({
        type: FETCH_PUBLIC_PROFILE,
        payload: {
            published: publishedPublicProfile,
            draft: draftPublicProfile,
        }
    })
};

export const updatePublicProfile = (data) => async (dispatch) => {
    dispatch({
        type: CLEAR_SERVER_ERROR,
        payload: null
    });

    try {
        let response = await authMultipartFormDataHttpClient.post(`/partner-public-profile`, data);

        return response.data;
    } catch (e) {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: {errors: serverErrorToErrorList(e)}
        });
        throw e;

    }

};

