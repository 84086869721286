import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Translate from "react-translate-component";
import React, { Component } from "react";

class PublishConfirmationModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.show}>
                <ModalHeader
                    toggle={() => {
                        this.props.setShow(!this.props.show);
                    }}
                >
                    Bitte beachten!
                </ModalHeader>
                <ModalBody>
                    <p>
                        <Translate content="profile.publish_confirmation_message" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-secondary"
                        onClick={this.props.onSaveAsDraft}
                    >
                        <Translate content="profile.save_as_draft" />
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={this.props.onPublish}
                    >
                        <Translate content="profile.published" />
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default PublishConfirmationModal;
