import React, { Component } from "react";
import { connect } from "react-redux";
import { authenticate } from "../../redux/actions/authActions";
import { inputFields } from "../_redux_forms/inputFields";
import { required, requiredAndEmail } from "../../utils/validate";
import { Field, reduxForm } from "redux-form";
import ServerError from "../common/ServerError";
import withNoAuth from "../auth/withNoAuth";
import Translate from "react-translate-component";
import FlashMessage from "../common/FlashMessage";
import bgImage from "../../assets/images/market_partner.png";
import { Link } from "react-router-dom";

class Login extends Component {
  onSubmit = (formProps) => {
    formProps.role = "partner";
    this.props.authenticate(formProps).then(
      (response) => {
        this.props.history.push("/public-profile");
      },
      (errorResponse) => {}
    );
  };

  render() {
    const { handleSubmit } = this.props;
    const { emailVerifiedMessage } = this.props.emailVerification;

    return (
      <section className="login-page">
        <div className="container ">
          <FlashMessage />
          <div className="auth-block ">
            {emailVerifiedMessage && (
              <div className="alert alert-success" role="alert">
                <Translate content="common.email_verify_para" />
              </div>
            )}
            <h5 className="auth-title">
              <Translate content="login.sign_in_to_your_account" />
            </h5>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-group">
                <label>
                  <Translate content="login.enter_email" />
                </label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputFields}
                  name="email"
                  validate={requiredAndEmail}
                  placeholder="email"
                />
              </div>
              <div className="form-group">
                <label>
                  <Translate content="login.password" />
                </label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputFields}
                  name="password"
                  validate={required}
                  placeholder="password"
                />
              </div>
              <ServerError />
              <div className="form-group text-right">
                <label className="form-check-label">
                  <Link to="/forgot-password">
                    <Translate content="login.forgot_your_password" />
                  </Link>
                </label>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  <Translate content="common.login" />
                </button>
              </div>
              <div className="form-group text-center">
                <Translate content="login.dont_have_an_account" />
                <Link to="/register">
                  <Translate content="login.sign_up_now" />
                </Link>
              </div>
              <p className="small text-center">
                <Translate content="login.login_para" />
              </p>
            </form>
          </div>
          <div className="login-image ">
            <img src={bgImage} alt="background-img" />
          </div>
        </div>
      </section>
    );
  }
}

Login = reduxForm({
  form: "login",
})(Login);
const mapStateToProps = (state) => {
  return {
    emailVerification: state.flashMessage,
  };
};
export default connect(mapStateToProps, {
  authenticate,
})(withNoAuth(Login));
