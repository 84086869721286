import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
import {AUTHENTICATE} from "./actionTypes";
import jwt from "jsonwebtoken";

const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(thunk)));

/**
 * syncing localStorage token to redux state
 */
const token = localStorage.getItem('token');
const city_code = localStorage.getItem('city_code');
const city_name = localStorage.getItem('city_name');

if (token) {
    const {sub} = jwt.decode(token);

    store.dispatch({type: AUTHENTICATE, payload: {token, city_code, city_name, user_id: sub}});
}

export default store;