import React, {Component} from "react";
import {connect} from "react-redux";

class ServerError extends Component {
    render() {
        return (
            <ul className="list-group">
                {(this.props.errors) ? this.props.errors.map((error, index) => (
                    <li className="list-group-item" key={index}>
                        <span><small className="text-danger">{error}</small></span>
                    </li>
                )) : null}
            </ul>)

    }
}

export default connect((state) => ({
    errors: state.serverError.errors
}))(ServerError);
