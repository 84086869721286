import {CHANGE_LANGUAGE} from '../actionTypes';

const initialState=  'de'||localStorage.getItem('language') || navigator.language.split('-')[0]
const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
};

export default languageReducer;