import React, { Component } from "react";
import withNoAuth from "../auth/withNoAuth";
import { connect } from "react-redux";
import { verifyEmail } from "../../redux/actions/authActions";
import { EMAIL_VERIFICATION_SUCCESS } from "../../redux/actionTypes";

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: true,
      error: "",
    };
  }

  componentDidMount() {
    let loc = this.props.history.location.search;
    let params = new URLSearchParams(loc);
    let token = params.get("token");
    if (token) {
      this.props
        .verifyEmail({ token })
        .then((res) => {
          this.props.emailVerified(res.message);
          this.props.history.push("/");
        })
        .catch((err) => {
          this.setState({ error: err.response.data.message, loading: false });
        });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    let { error, loading } = this.state;
    return (
      <div>
        <div className="container">
          <div style={{ marginTop: "4rem", textAlign: "center" }}>
            {error && <div className="alert alert-danger">{error}</div>}
            {loading && (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: (data) => dispatch(verifyEmail(data)),
    emailVerified: (data) =>
      dispatch({ type: EMAIL_VERIFICATION_SUCCESS, payload: data }),
  };
};
export default withNoAuth(connect(null, mapDispatchToProps)(EmailVerification));
