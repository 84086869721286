import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import jwt from "jsonwebtoken";
import NoAuthHeader from "../common/NoAuthHeader";
import Footer from "../common/Footer";

export default ChildComponent => {
    class ComposedComponent extends Component {
        componentDidMount() {
            this.shouldNavigateAway();
        }

        componentDidUpdate() {
            this.shouldNavigateAway();
        }

        shouldNavigateAway() {
            if (this.props.authentication.token) {
                const {exp} = jwt.decode(this.props.authentication.token);
                // check if the token is valid, if not redirect to landing page
                if ((exp * 1000) >= (new Date()).getTime()) {
                    this.props.history.push("/public-profile");
                }
            }
        }

        render() {
            return (
                <Fragment>
                    <NoAuthHeader/>
                    <ChildComponent {...this.props} />
                    <Footer/>
                </Fragment>);
        }
    }

    function mapStateToProps(state) {
        return {
            authentication: state.authentication
        };
    }

    return connect(mapStateToProps)(ComposedComponent);
};
