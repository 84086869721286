import {CHANGE_LANGUAGE} from "../actionTypes";

export const change_language = (language) => async (dispatch) => {
    localStorage.setItem('language', language);
    dispatch({
        type: CHANGE_LANGUAGE,
        payload: language
    });
};

