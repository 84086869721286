import React, {Fragment} from "react";
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'

export const multiSelect = ({
                                input, data, valueField, textField, meta: {touched, error, warning}
                            }) =>
    (<Fragment>
            <Multiselect {...input}
                         onBlur={() => input.onBlur()}
                         value={input.value || []}
                         data={data}
                         valueField={valueField}
                         textField={textField}
            />
            {touched && (
                (error && (<span><small className="form-text text-danger">{error}</small></span>))
                || (warning && <span>{warning}</span>)
            )}
        </Fragment>
    )