import React, { Component } from "react";
import {
  fetchCodeList,
  updateCode,
} from "../../redux/actions/uploadCodeActions";
import Translate from "react-translate-component";
import moment from "moment";
import momentLocaliser from "react-widgets-moment";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import withAuth from "../auth/withAuth";
import Pagination from "react-js-pagination";
import "react-confirm-alert/src/react-confirm-alert.css";
import RedeemConfirmationModal from "../modals/RedeemConfirmationModal";
import NoTableData from "../common/NoTableData";

momentLocaliser(moment);

class Codes extends Component {
  state = {
    page: 1,
    code: "",
    value: "",
    status: [],
    created_on_from: null,
    created_on_to: null,
    sold_on_to: null,
    sold_on_from: null,
    showConfirmationModal: false,
    code_to_be_redeemed: null,
    partner_id: null,
  };

  setShowConfirmationModal = (showConfirmationModal) => {
    this.setState({ showConfirmationModal: showConfirmationModal });
  };

  async componentDidMount() {
    await this.setState({ partner_id: this.props.authentication.user_id });
    this.search();
  }

  search = () => {
    const data = {
      page: this.state.page,
      code: this.state.code,
      value: this.state.value,
      status: this.state.status.value,
      partner_id: this.state.partner_id,
    };

    if (this.state.created_on_from !== null) {
      data.created_on_from = moment(this.state.created_on_from).format(
        "YYYY-MM-DD"
      );
    }
    if (this.state.created_on_to !== null) {
      data.created_on_to = moment(this.state.created_on_from).format(
        "YYYY-MM-DD"
      );
    }
    if (this.state.sold_on_from !== null) {
      data.sold_on_from = moment(this.state.sold_on_from).format("YYYY-MM-DD");
    }
    if (this.state.sold_on_to !== null) {
      data.sold_on_to = moment(this.state.sold_on_to).format("YYYY-MM-DD");
    }

    this.props.fetchCodeList(data);
  };

  resetFilter = async (event) => {
    event.preventDefault();
    await this.setState({
      page: 1,
      code: "",
      value: "",
      status: [],
      created_on_from: null,
      created_on_to: null,
      sold_on_from: null,
      sold_on_to: null,
    });
    this.search();
  };

  _handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.search();
    }
  };

  getStatusColor = (status) => {
    let className = null;
    switch (status) {
      case "active":
        className = "status status-active";
        break;
      case "inactive":
        className = "status status-inactive";
        break;
      case "expired":
        className = "status status-expired";
        break;
      case "redeemed":
        className = "status status-redeemed";
        break;
      default:
        className = "";
    }

    return className;
  };

  getStatusText = (status) => {
    let label = null;
    switch (status) {
      case "active":
        label = <Translate content="tooltip.active" />;
        break;
      case "inactive":
        label = <Translate content="tooltip.inactive" />;
        break;
      case "expired":
        label = <Translate content="tooltip.expired" />;
        break;
      case "redeemed":
        label = <Translate content="tooltip.redeemed" />;
        break;
      default:
        label = status.toUpperCase();
    }

    return label;
  };

  renderActionView = (code) => {
    if (code.status === "active") {
      return (
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            this.setState({ code_to_be_redeemed: code.code });
            this.setShowConfirmationModal(true);
          }}
        >
          <Translate content="codes.set_as_redeemed" />
        </button>
      );
    }
  };

  render() {
    const { codeList } = this.props;

    const options = [
      { value: "active", label: <Translate content="codes.sold" /> },
      {
        value: "redeemed",
        label: <Translate content="codes.redeemed" />,
      },
    ];

    return (
      <section>
        <div className="app-title">
          <h5 className="mr-auto">
            <Translate content="codes.code_list" />
          </h5>
        </div>
        <div className="app-block partner-page">
          <div className="tile">
            <div className="tile-title-w-btn">
              <h5 className="title text-primary">
                <Translate content="codes.search" />
              </h5>
              <button className="btn btn-link" onClick={this.resetFilter}>
                <Translate content="codes.reset" />
                <i className="icon-arrows-cw text-muted ml-2"></i>
              </button>
            </div>
            <div className="app-custom-filter">
              <div className="row align-content-center">
                <div className="custom-11">
                  <div className="form-row mb-md-2">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <div className="search-form">
                          <Translate
                            className="form-control"
                            type="text"
                            component="input"
                            attributes={{
                              placeholder: "codes.search_code",
                            }}
                            value={this.state.code}
                            onChange={(event) => {
                              this.setState({
                                code: event.target.value,
                              });
                            }}
                            onKeyDown={this._handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <div className="search-form">
                          <Translate
                            className="form-control"
                            type="text"
                            component="input"
                            attributes={{
                              placeholder: "codes.search_price",
                            }}
                            value={this.state.value}
                            onChange={(event) => {
                              this.setState({
                                value: event.target.value,
                              });
                            }}
                            onKeyDown={this._handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group select-option">
                        <Select
                          placeholder="Suche Status"
                          options={options}
                          value={this.state.status}
                          onChange={(value) => {
                            this.setState({
                              status: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="custom-1 search-holder">
                  <button
                    className="btn btn-primary mt-0"
                    onClick={this.search}
                  >
                    <i className="icon-magnifier icon-2x"></i>
                    <span>
                      <Translate content="codes.search" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-table table table-hover">
          <div className="table-responsive">
            <table
              className="table table-borderless data-table nowrap"
              id="my-table"
            >
              <thead className="thead-light">
                <tr>
                  <th className="th-text">
                    <span data-tip data-for={"code"}>
                      <Translate content="codes.code" />
                    </span>
                  </th>
                  <th className="th-text">
                    <span data-tip data-for={"value"}>
                      <Translate content="codes.value" />
                    </span>
                  </th>
                  <th className="th-text">
                    <span data-tip data-for={"created"}>
                      <Translate content="codes.created_at" />
                    </span>
                  </th>
                  <th className="th-text">
                    <span data-tip data-for={"redeem_date"}>
                      <Translate content="codes.redeemed_on" />
                    </span>
                  </th>
                  <th className="text-center th-text">
                    <span data-tip data-for={"statuses"}>
                      <Translate content="codes.status" />
                    </span>
                  </th>
                  <th className="text-center th-text">
                    <Translate content="codes.action" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <NoTableData list={codeList.data} colSpan="6" />
                {codeList.data.map((code, index) => (
                  <tr key={index}>
                    <td className="text-uppercase">{code.code}</td>
                    <td>€{code.value}</td>
                    <td>
                      {code.created_at
                        ? moment(code.created_at).format("DD.MM.YYYY")
                        : ""}
                    </td>
                    <td>
                      {code.redeemed_at
                        ? moment(code.redeemed_at).format("DD.MM.YYYY")
                        : ""}
                    </td>
                    <td>
                      <div
                        className={this.getStatusColor(code.status)}
                        data-tip
                        data-for={"status_" + index}
                      >
                        <ReactTooltip id={"status_" + index}>
                          {this.getStatusText(code.status)}
                        </ReactTooltip>
                      </div>
                    </td>
                    <td className="text-center">
                      {this.renderActionView(code)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activeClass="active"
          activeLinkClass=""
          disabledClass="disabled"
          prevPageText="Vorherige"
          nextPageText="Nächste"
          firstPageText="Erste"
          lastPageText="Letzte"
          activePage={this.state.page}
          itemsCountPerPage={codeList.meta.per_page}
          totalItemsCount={codeList.meta.total}
          pageRangeDisplayed={5}
          onChange={async (page) => {
            await this.setState({ page: page });
            this.search();
          }}
        />
        <RedeemConfirmationModal
          show={this.state.showConfirmationModal}
          setShow={this.setShowConfirmationModal}
          onYes={() => {
            this.props
              .updateCode({
                code: this.state.code_to_be_redeemed,
                status: "redeemed",
              })
              .then((response) => {
                this.search();
              });
            this.setShowConfirmationModal(false);
          }}
          onNo={() => {
            this.setShowConfirmationModal(false);
          }}
        />
      </section>
    );
  }
}

Codes = connect(
  (state) => ({
    codeList: state.codeList,
    authentication: state.authentication,
  }),
  {
    fetchCodeList,
    updateCode,
  }
)(Codes);

export default withAuth(Codes);
