import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form'
import authReducer from "./authReducers";
import serverErrorReducer from "./serverErrorReducers";
import profileReducer from "./profileReducers";
import {flashMessageReducer} from "./flashMessageReducers";
import codeListReducer from "./codeListReducers";
import languageReducer from "./languageReducers";
import publicProfileReducer from "./publicProfileReducers";
import countryReducer from "./countryReducers";
import categoryReducer from "./categoryReducers";
import cityReducer from "./cityReducers";

const rootReducer = combineReducers({
    serverError: serverErrorReducer,
    form: formReducer,
    flashMessage: flashMessageReducer,
    authentication: authReducer,
    profile: profileReducer,
    publicProfile: publicProfileReducer,
    codeList: codeListReducer,
    language: languageReducer,
    countryList: countryReducer,
    cityList: cityReducer,
    categoryList: categoryReducer,
});

export default rootReducer;