import React, {Component, Fragment} from 'react';
import 'babel-polyfill';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {clearServerError} from "../../redux/actions/serverErrorActions";

class Layout extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.props.clearServerError();
    }

    render() {
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {language: state.language};
}

export default connect(mapStateToProps, {
    clearServerError
})(withRouter(Layout));
